import { customElement } from 'lit/decorators.js';
import { sbkMainNavItemsStyle } from './main-nav-items.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-main-nav-items')
export class SbkMainNavItems extends LitElement {
    static get styles() {
        return sbkMainNavItemsStyle;
    }

    render() {
        return html`
            <ul>
                <slot></slot>
            </ul>
        `;
    }
}
